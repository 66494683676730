/* Modal overlay */
.modalOverlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal content */
.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 100%;
  border-radius: 8px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  padding: auto;
}
.openButton_light {
  background-color: var(--cream);
  color: var(--navy);
}
.openButton_dark {
  background-color: #4158d0;
  color: var(--cream);
}
.openButton_light,
.openButton_dark {
  margin: 2rem;
  border: none;

  text-align: center;
  cursor: pointer;
  border-radius: 1000rem;
  justify-content: center;
  align-items: center;
  padding: 0.875rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  display: flex;
  width: fit-content;
}
.openButton_light:hover {
  background-color: var(--lavender);
  color: var(--navy);
}
.openButton_dark:hover {
  background-color: #e02dd7;
}
.closeButton {
  /* z-index: 9999;
  position: absolute; */
  /* top: 120px;
  right: 20px; */
}

/* Responsive styles */
@media (max-width: 600px) {
  .modalContent {
    max-width: 90%;
    width: 90%;
  }
}

@media (max-width: 400px) {
  .modalContent {
    max-width: 95%;
    width: 95%;
  }
}

.container_loading {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--navy);
}

.box {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box .b {
  border-radius: 50%;
  border-left: 4px solid;
  border-right: 4px solid;
  border-top: 4px solid transparent !important;
  border-bottom: 4px solid transparent !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ro 2s infinite;
}

.box .b1 {
  border-color: var(--qwik-light-purple);
  width: 280px;
  height: 280px;
}

.box .b2 {
  border-color: var(--cream);

  width: 250px;
  height: 250px;
  animation-delay: 0.2s;
}

.box .b3 {
  border-color: var(--qwik-light-purple);

  width: 220px;
  height: 220px;
  animation-delay: 0.4s;
}

.box .b4 {
  border-color: var(--cream);
  width: 190px;
  height: 190px;
  animation-delay: 0.6s;
}

@keyframes ro {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  50% {
    transform: translate(-50%, -50%) rotate(-180deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

.header h3 {
  margin: 0;
  color: var(--navy);
  font-size: 4.75rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  line-height: 100%;
}
.header h3 div {
  width: auto;
  flex-wrap: wrap;
  justify-content: start;
  padding-top: 0;
  padding-bottom: 0;
}
/* App.css (o il nome del tuo file CSS) */
.accordion_box {
  border-top: solid 5px #662d91;
  border-radius: 20px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
}
/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  border-top: solid 5px transparent;

  align-items: center;
  display: flex;
  font-weight: 600;
  color: var(--navy);
  cursor: pointer;
  padding: 18px 0px 0px 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  font-size: 1.4rem;
  background-color: transparent;
}
.accordion span {
  color: var(--qwik-light-purple);
  padding-right: 10px;
}
.accordion img {
  width: 20px;
  display: inline-block;
}
.accordion:hover {
  border-radius: 20px;
}
/* Style the accordion panel. Note: hidden by default */
.panel {
  font-weight: 300;
  line-height: 140%;
  font-size: 1.3rem;
  padding: 0px 50px 0px 62px;
  overflow: hidden;
  height: 0;
}

/* Show the panel when the button is active */
.up,
.down {
  margin-left: auto;
}
.up {
  transform: rotate(180deg);
}

.active + .panel {
  height: 100%;
}

@media screen and (max-width: 1150px) {
  .panel {
    padding: 0px 0px 0px 55px;
    overflow: hidden;
    height: 0;
  }
  .panel p {
    font-size: 1rem;
    line-height: 140%;
    margin: 0;
    margin-bottom: 1rem;
  }
  .accordion {
    padding: 10px;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 1150px) and (max-height: 600px) {
}
@media screen and (max-width: 767px) {
  .accordion {
    font-size: 1rem;
  }
  .panel {
    padding: 0px 0px 0px 43px;
  }
  .panel p {
    margin-bottom: 10px;
  }
}

@media screen and (max-height: 700px) {
  .language_row img {
    display: none;
  }
}
@media screen and (max-height: 850px) {
  .language_row img {
    width: 200px;
  }
}

.offCanvas_nav {
  z-index: 3;
  display: flex;
  justify-content: end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0px;
}

.background {
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: var(--cream);
}

.offCanvas_button {
  background-color: var(--cream);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 12.5px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.offCanvas_button svg {
  padding-top: 3px;
  padding-left: 1px;
}
.offCanvas_nav ul,
li {
  margin: 0;
  padding: 0;
}
.offCanvas_nav ul {
  z-index: 99999;
  position: absolute;
  top: 200px;
  width: 100vw;
  left: 0;
  transition: all 0.2;
}

.offCanvas_nav li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  color: var(--navy);
  border-radius: 5px;
  padding: 3px 0px;
  width: 200px;
  height: fit-content;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hero {
  /* background-image: url("../../../../src/media/18.png");
  background-size: cover;
  backdrop-filter: blur(10px); */
  background-color: #4541fe;
  background-image: linear-gradient(0deg, #4541fe 0%, #000000 66%);
  height: calc(100%);
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.openButton {
  margin: 2rem;
  border: none;
  background-color: var(--cream);
  color: var(--navy);
  text-align: center;
  cursor: pointer;
  border-radius: 1000rem;
  justify-content: center;
  align-items: center;
  padding: 0.875rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  display: flex;
  width: fit-content;
}
.openButton:hover {
  background-color: var(--lavender);
}

.wave {
  background-image: url("../../../../public/media/63c74fd611d6b51706a71080_hero-poster.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero a {
  margin-top: 100px;
  background-color: var(--cream);
  color: var(--navy);
  text-align: center;
  cursor: pointer;
  border-radius: 1000rem;
  justify-content: center;
  align-items: center;
  padding: 0.875rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  display: flex;
  width: fit-content;
}
.hero a:hover {
  background-color: var(--lavender);
}
.loghi {
  position: absolute;
  bottom: 5%;
  column-gap: 20px;
  justify-content: center;
  display: flex;
}
.loghi div {
  display: flex;
  align-items: end;
  justify-content: center;
  align-content: space-around;
  flex-wrap: wrap;
  height: 50px;
  width: 15%;
}
.loghi div img {
  display: inline-block;
  max-width: 100%;
  max-height: 40px;
  height: auto;
  width: auto;
}
.loghi div:nth-child(3) img {
  max-width: 50px;
}

@media screen and (max-width: 850px) {
  .loghi {
    bottom: 10%;
    height: 50px;
  }
  .loghi div img {
    max-height: 20px;
  }
}
.hero h1 {
  color: var(--cream);

  font-size: 4.75rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  line-height: 120%;
  margin-bottom: 50px;
}
@media screen and (max-width: 991px) {
  .hero h1 {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 767px) {
  .hero h1 {
    font-size: 3rem;
  }
}

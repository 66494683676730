.container_noPage {
  z-index: -1;
  background-color: var(--cream);
  color: var(--navy);
  font-size: 100%;
  height: 100vh;
  line-height: 1.6;
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button {
  width: fit-content;
  border: none;
  background-color: var(--lavender);
  color: var(--navy);
  text-align: center;
  cursor: pointer;
  border-radius: 1000rem;
  justify-content: center;
  align-items: center;
  padding: 0.875rem 1.5rem;
  font-weight: 600;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  display: flex;
  width: fit-content;
  font-size: 1.2em;
  text-decoration: none;
  margin-top: 3em;
  left: 50%;
  transition: all 0.3s linear;
}

.button:hover {
  background-color: var(--navy);
  color: #fff;
}

.container_noPage p {
  margin: 0;
  z-index: 2;
  font-size: 2em;
  text-align: center;
  font-weight: 100;
}

.container_noPage h1 {
  z-index: 2;
  text-align: center;
  font-size: 15em;
  font-weight: 100;
  margin: 0;
  color: var(--navy);
}

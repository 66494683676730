.cta_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cta_box {
  background-color: var(--breeze);
  margin-top: 77px;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.cta_text {
  padding-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.cta_text h3 div {
  width: auto;
  letter-spacing: -0.05em;
}
.cta_text h3 {
  color: var(--cream);
  padding: 10px 0px;
  margin-top: 0;
  text-align: center;
  font-size: 5.75rem;
  letter-spacing: -0.05em;
  margin-bottom: 0;
  font-weight: 700;
}
.cta_text p {
  margin: 0;
  margin-top: 2rem;
  height: fit-content;
  font-size: 2.7rem;
  font-weight: 200;
  letter-spacing: normal;
}
.cta_img {
  padding-right: 2rem;
  margin-left: 2rem;
}
.cta_img img {
  width: 400px;
}
@media screen and (max-width: 1400px) {
  .cta_img img {
    width: 300px;
  }

  .cta_text p {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 960px) {
  .cta_img img {
    width: 300px;
  }
  .cta_text h3 {
    font-size: 3.5rem;
  }
  .cta_text p {
    font-size: 1.2rem;
  }
  .cta_text button {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 550px) {
  .cta_img {
    display: none;
  }
  .cta_text {
    padding: 0;
    margin: 0;
  }
}

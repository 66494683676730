.footer {
  background-color: var(--navy);
  padding: 80px 0;
}

.container {
  width: 100vw;
  height: 100vh;
  max-width: 1170px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

ul {
  list-style: none;
}

.footer_col {
  height: 300px;
  width: 25%;
  padding: 0 15px;
  text-align: start;
}

.footer_col h4 {
  font-size: 18px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

.footer_col h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #e91e63;
  width: 50px;
  height: 2px;
}

.footer_col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer_col ul li a {
  color: #ddd;
  display: block;
  font-size: 1rem;
  font-weight: 300;
  text-transform: capitalize;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer_col ul li a:hover {
  color: #fff;
  padding-left: 7px;
}
.social_links {
  display: flex;
  margin-left: 40px;
}
.footer_col .social_links img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  margin: 0 10px 10px 0;
  line-height: 40px;
  transition: all 0.5s ease;
}

@media (max-width: 940px) {
  .container {
    flex-direction: column;
  }
  .footer_col {
    height: fit-content;
  }
}
@media (max-width: 767px) {
  .container {
    margin-left: 2rem;
    margin-top: 30px;
  }
  .footer_col {
    width: 50%;
    margin-bottom: 0;
  }
}

@media (max-width: 574px) {
  .footer_col {
    width: 100%;
  }
  .footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0;
    height: 100vh;
  }
}

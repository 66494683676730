.people_section {
  height: calc(100vh);

  background-color: var(--navy);
  padding-left: 5rem;
  padding-right: 5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}
.people_text_box {
  margin-top: 75px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: var(--cream);
  position: absolute;
  max-width: 800px;
}
.people_section h2 {
  text-align: center;
  letter-spacing: -0.05em;
  margin-top: 0;
  font-size: 5rem;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 2rem;
}
.people_section p {
  color: var(--qwik-dark-text);
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1400px) {
  .people_text_box h2 {
    font-size: 3.5rem;
  }
  .people_text_box p {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 991px) {
  .people_text_box {
    width: 70%;
  }
  .people_section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .people_text_box {
    width: 100%;
  }
  .people_text_box p {
    width: 90%;
  }
  .people_text_box {
    width: 90%;
  }
}

.people_section img {
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 80rem;
  overflow-clip-margin: content-box;
  overflow: clip;
}

@media screen and (max-width: 767px) {
  .people_section h2 {
    font-size: 2.25rem;
  }
  .people_section p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 991px) {
  .people_section h2 {
    font-size: 3rem;
  }
  .people_section p {
    font-size: 1.3rem;
  }
}

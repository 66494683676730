.word {
  padding-right: 7.5px;
  padding-left: 7.5px;
  padding-bottom: 5px !important;
}

.word_colored {
  padding-right: 15px;
  padding-bottom: 5px !important;
  background-image: linear-gradient(90deg, #4158d0 0%, #e02dd7 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.dot {
  background-image: linear-gradient(90deg, #4158d0 0%, #e02dd7 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.words_container {
  margin-left: auto;
  margin-right: auto;
  width: 1150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 1190px) {
  .words_container {
    width: 100%;
  }
}

.solution_container {
  color: #000;
  height: calc(100vh - 75px);
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.title_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.title_box h3 {
  text-align: center;
  color: black;
  letter-spacing: -0.05em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 5.75rem;
  line-height: 90%;
}

.highlight {
  background-image: linear-gradient(90deg, #4158d0 0%, #e02dd7 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.button_box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.openButton {
  display: block;
  text-align: center;
  cursor: pointer;
  border-radius: 1000rem;
  padding: 0.875rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;

  color: white;
  transition: all 0.3s;
}
.openButton:hover {
  color: #4158d0;
  background-color: var(--lavender);
  border: #4158d0 2px solid;
}
.solution_box {
  height: auto;
  text-align: center;
}
.solution_box h4 {
  margin-bottom: 0px;
  color: #000;
}
.solution_box h4,
.scrolling_text {
  color: var(--qwik-dark-text);
  letter-spacing: -0.05em;
  font-size: 3rem;
  font-weight: 700;
  line-height: 100%;
  text-wrap: nowrap;
  height: 70px;

  overflow-y: visible;
}
.scrolling_text {
  width: fit-content !important;
}
/* .highlight {
  color: #000;
} */
.solution_container .solution_swiper {
  height: 70px !important;
  width: 100%;
}
.solution_container .solution_swiper .swiper-slide {
  width: fit-content;
  -bottom: 5px;
}
.solution_container .solution_swiper .swiper-slide span {
  font-size: 2rem;
  height: 70px !important;
  padding: 5px 0px;
}
.solution_box h4 span {
  font-size: 1rem;
  letter-spacing: normal;
  line-height: 130%;
  color: #e02dd7;
}
.solution_button_box .openButton {
  color: var(--navy) !important;
  margin-bottom: 0 !important;
}
@media (max-width: 1400px) {
  .title_box h3 {
    font-size: 4rem;
  }
  .solution_box h4,
  .scrolling_text {
    font-size: 2.5rem;
  }
}
@media (max-width: 911px) {
  .title_box h3 {
    font-size: 3rem;
  }
  .solution_box h4,
  .scrolling_text {
    font-size: 2rem;
  }
}
@media (max-width: 390px) {
  .title_box h3 {
    font-size: 3rem;
  }
}

.softdev_slide {
  width: 100vw;
  background-color: var(--white-background);
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
  flex-direction: column;
  margin-bottom: 25px;
}
.container_service {
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: row;
}
.left_box,
.right_box {
  text-align: start;
  max-width: 50em;
  width: 30%;
}
.list li {
  margin: 0.75rem 0;
}
.left_box {
  width: 32%;
  padding-right: 5%;
}
.left_box h3 {
  margin: 0;
  margin-bottom: 50px;
  color: var(--navy);
  font-size: 3.75rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  line-height: 100%;
}
.left_box h3 div {
  width: auto;
  flex-wrap: wrap;
  justify-content: start;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.left_box h3 div:first-child div {
  background-image: linear-gradient(90deg, #4158d0 0%, #e02dd7 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.left_box p {
  border-top: solid 5px #662d91;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 20px;
}
.left_box p {
  z-index: 1;
  font-weight: 300;

  line-height: 140%;
  font-size: 1.3rem;
}
.loghi_container {
  /* margin: 2em 0; */
}
.logo_swiper {
  width: 100%;
  margin: 0 1rem;
  max-width: 95vw !important;
  margin: 0;
}

.logo_swiper .logo_slide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.loghi_container img {
  width: auto;
  margin: 0 15px;
  max-height: 50px;
  max-width: 6rem;
}
@media screen and (min-width: 1320px) and (min-height: 700px) {
  .softdev_slide {
  }
}
@media screen and (max-width: 1400px) {
  .left_box,
  .right_box {
    max-width: 27rem;
    width: auto;
  }
  .left_box h3 {
    font-size: 3rem;
  }
  .left_box p {
    font-size: 1rem;
  }
  .right_box p {
    padding-right: 20px;
  }
}
@media screen and (max-width: 1150px) {
  .loghi_container img {
    height: 30px;
  }
  .softdev_slide {
  }
  .right_box {
  }
  .left_box h3 {
    margin-bottom: 2.25rem;
  }
  .left_box {
  }
  .left_box p {
    margin-left: 0;
  }
}
@media screen and (max-width: 991px) {
  .left_box h3 {
    margin-bottom: 20px;
    font-size: 4rem;
  }
  .container_service {
    padding: 0 10px;
    flex-direction: column;
  }
  .softdev_slide {
    padding-top: 25px;
    justify-content: flex-start;
  }
  .left_box {
    padding: 0;
  }
  .right_box {
  }
  .right_box img {
    max-height: 300px;
  }
  .loghi_container {
    margin: 1.5rem 0;
  }
  .left_box p {
    margin: 0;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
    line-height: normal;
  }
}
@media screen and (max-width: 767px) {
  .left_box h3 {
    font-size: 3.2rem;
  }
  .loghi_container {
    margin: 1rem 0;
  }
  .loghi_container img {
    max-width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 520px) {
  .loghi_container {
    height: 30px;
  }
  .loghi_container img {
    height: 30px;
  }
}
@media screen and (max-width: 767px) and (max-height: 684px) {
}
@media screen and (max-width: 360px) and (max-height: 800px) {
  .left_box h3 {
    font-size: 2.7rem;
  }
}
@media screen and (max-width: 360px) {
  .left_box h3 {
    font-size: 2rem;
    height: auto;
    margin-bottom: 10px;
  }
  .left_box p,
  .right_box p {
    margin-bottom: 10px;
  }
}
/* @media screen and (max-height: 650px) {
  .loghi_container {
    display: none;
  }
} */

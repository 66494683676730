.section_row {
  height: calc(100% - 75px);
  margin-top: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row {
  width: 100%;
  max-width: 80rem;
  grid-column-gap: 2.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5rem;
  padding-right: 5rem;
  display: grid;
}
.col img {
  vertical-align: middle;
  width: auto;
  height: 70px;
}
.col p {
  color: var(--navy);
  margin: 0;
  font-size: 1.3rem;
  letter-spacing: 0.01em;
  line-height: 1.3em;
}
.col {
  grid-row-gap: 1rem;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 991px) {
  .row {
    grid-auto-flow: row;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .row p {
    font-size: 1rem;
  }
}
@media screen and (max-height: 800px) and (max-with: 1200px) {
  .col img {
    display: none;
    width: 20px !important;
  }
  .col p {
    line-height: normal;
  }
}
@media screen and (max-width: 767px) {
  .col img {
    width: auto;
    height: 40px;
  }
  .row {
    grid-column-gap: 0rem;
    grid-row-gap: 0.5rem;
    order: 3;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
}

.col h3 {
  color: #000;
  letter-spacing: -0.05em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 100%;
}
@media screen and (max-width: 1400px) {
  .col h3 {
    font-size: 2rem;
  }
  .col p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .col h3 {
    font-size: 1.3rem;
  }
  .col img {
    width: auto;
    height: 30px;
  }
}

@media screen and (max-width: 479px) {
  .row {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (min-height: 800px) and (min-width: 300px) {
  .row {
    grid-row-gap: 4rem;
  }
  .col img {
    height: 50px;
  }
}

:root {
  width: 100vw !important;
  overflow-x: hidden !important;
  --qwik-dark-blue: #101722;
  --qwik-light-blue: #4357d0;
  --qwik-light-purple: #f555a4;
  --qwik-dark-purple: #512ac2;
  --qwik-dirty-black: #1d2033;
  --qwik-dark-background: #151934;
  --qwik-dark-text: #afc0e6;
  --white-background: #f9f0ff;
  --navy: #101722;
  --lavender: #d9c6ff;
  --cream: #f9f0ff;
  --breeze: #4541fe;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
html {
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif !important;
}
body {
  background-color: var(--white-background);
  padding: 0;
  margin: 0;
  line-height: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pillar_slide {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container {
  margin-top: 75px;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #4541fe;
}
.box {
  color: var(--cream);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.container h3 {
  padding: 10px 0px;

  margin-top: 0;
  text-align: center;
  font-size: 5.75rem;
  letter-spacing: -0.05em;
  margin-bottom: 0;
  font-weight: 700;
}
.container h3 p {
  margin: 0;
  height: fit-content;
  font-size: 1.7rem;
  font-weight: 200;
  letter-spacing: normal;
}
.container h3 p div {
  padding: 5px;
}
.pillar_img_box {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  max-height: 60vh;
  margin-bottom: 1rem;
}
.pillar_img_box img {
  object-fit: contain;
  border-radius: 20px;
  max-width: 100%;
  height: auto;
}
@media (max-width: 1400px) {
  .container h3 {
    font-size: 4rem;
  }
}
@media (max-width: 911px) {
  .pillar_slide {
    justify-content: flex-start;
  }
  .container {
    height: calc(100vh - 75px);
    overflow-x: hidden;
  }
  .pillar_img_box {
    padding: 0;
  }

  .container h3 {
    line-height: 3.5rem;
    font-size: 4rem;
  }
  .container h3 p {
    line-height: 1rem;
    letter-spacing: normal;
    font-size: 1.4rem;
  }
}
@media (max-width: 850px) {
  .container h3 {
    font-size: 3rem;
  }
  .container h3 p {
    line-height: 2rem;
    font-size: 1rem;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .pillar_img_box {
    height: auto;
    width: 120%;
  }
}
.container svg  {
}

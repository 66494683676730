.navbar {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%; /* From https://css.glass */
  background: #000000bd;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.7px);
  -webkit-backdrop-filter: blur(11.7px);
  display: flex;
  justify-content: center;
  height: 75px;
}

.nav_box {
  color: white;
}

.nav_box img {
  height: 50px;
  padding: 12.5px;
}
.nav_box:nth-child(1) {
  position: absolute;
  left: 0;
  top: 0;
  width: fit-content;
  display: felx;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.5s;
}
.nav_box:nth-child(1):hover img {
  background-color: rgba(255, 255, 255, 0.393);
}
.nav_box:nth-child(1) svg {
  height: 50px;
  padding: 15px;
}
.nav_box:nth-child(2) {
  align-items: center;
  display: flex;
}
.nav_box:nth-child(2) .nav_link {
  padding: 14px 16px;
  font-size: 1rem;
  font-weight: 600;
  margin: 12px 0px;
}
.nav_link {
  cursor: pointer;
  color: var(--cream);
  text-decoration: none;
}
.nav_link_active {
  color: var(--cream);
  text-decoration: none;
}

.nav_link::after {
  margin-top: 4px;
  content: "";
  width: 0%;
  height: 2px;
  background: var(--qwik-light-purple);
  display: block;
  transition: 0.3s;
}
.nav_link_active::after {
  margin-top: 4px;
  content: "";
  width: 100%;
  height: 2px;
  background: var(--qwik-light-purple);
  display: block;
  transition: 0.3s;
}
.nav_link:hover::after {
  width: 100%;
}
.nav_box:nth-child(3) {
  display: none;
  align-items: center;
  margin-right: 15px;
}

.nav_button {
  cursor: pointer;
  background-color: #4158d0;
  color: white;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.05em;
  border-radius: 1em;
  border: none;
  color: ghostwhite;
  overflow: hidden;
  transition: all 0.2s;
}
.nav_button:hover {
  color: #4158d0;
  background-color: ghostwhite;
}

/* The Overlay (background) */
.nav_box span {
  display: none;
  padding: 15px;
}
@media screen and (max-width: 1124px) {
  .navbar {
    justify-content: end;
  }
  .nav_box span {
    display: block;
  }
  .nav_box:nth-child(2) {
    display: none;
  }
  .nav_box:nth-child(3) {
    display: block;
  }
}
.overlay {
  z-index: 99999;

  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  overflow-y: hidden;
  height: 0%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%; /* 100% width */
  height: 100%;
  text-align: center; /* Centered text/links */
}

.overlay_link {
  display: block;
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  z-index: 99999999;
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
.fullHeight {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  overflow-y: hidden;
  height: 100vh;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

  overflow-y: hidden;
}
.noHeight {
  height: 0vh;
}
/* Stile per il dropdown */
.dropdown {
  z-index: 0;
  position: absolute;
  right: 80px;
  top: 26.5px;
}

/* Stile per il pulsante del dropdown */
.btn {
  font-family: "Inter", sans-serif !important;
  width: 30px;
  background-color: #ffffff; /* Colore dello sfondo */
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0;
  border-radius: 0.25rem;
}
.btn img {
  height: 20px;
}

/* Stile per il pulsante del dropdown quando è aperto */
.dropdownToggle {
  cursor: pointer;
}

/* Stile per il menu dropdown */
.dropdownMenu {
  position: absolute;
  top: 50px;
  left: 0;
  display: none;
  z-index: 1;
  min-width: 100%;
  margin: 0;
  font-size: 1rem;
  text-align: left;
  background-color: #ffffff; /* Colore dello sfondo */
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

/* Stile per gli elementi del menu dropdown */
.dropdownItem {
  font-family: "Inter", sans-serif !important;

  text-decoration: none;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0.25rem 0.5rem;
  clear: both;
  font-weight: normal;
  line-height: 1.5;
  color: #212529; /* Colore del testo */
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.dropdownItem img {
  height: 30px;
  padding-right: 0.7rem;
}

/* Stile per gli elementi del menu dropdown al passaggio del mouse */
.dropdownItem:hover {
  background-color: rgba(
    0,
    123,
    255,
    0.25
  ); /* Colore dello sfondo al passaggio del mouse */
}

/* Stile per il menu dropdown quando è aperto */
.show {
  display: block !important;
}

#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.home_slide {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
}
.swiper-container {
  height: 100%;
}
